const NoResults = ({lastQuery}) => {
    return (
        <div className="wrapper-grid">
            <div className="row">
                <div className="noneResults">
                    <h3 className="noresults">No Search Results Found For: <div className="goldText">{lastQuery}</div>.</h3>
                </div>
            </div>
        </div>
    )
}
export default NoResults;