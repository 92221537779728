import Card from './Card';
import Header from './Header';
import Metrics from './Metrics';
import NoResults from './NoResults';
import FilteredToZero from './FilteredToZero'
import PlaceholderCard from './CardPlaceholder';

const Container = ({ items,
     lastQuery,
     setSort,
     setType,
     setSites,
     isFilteringSort,
     runSearch,
     filteredToZero,
     setPriceMin,
     setPriceMax,
     setPWCCWeeklies,
     setPWCCPremiers,
     lastBody,
     setAddSavedSearch,
     addSavedSearch,
     savingSearch, }) => {
    const data = items.data;
    const count = items.count;

    return (<>

        {(count === 0 && !filteredToZero) ? <NoResults lastQuery={lastQuery} /> :

            <div id="content">
                <Header count={items.count} 
                        lastQuery={lastQuery} 
                        highResultCount={items.ebRc}
                        setAddSavedSearch={setAddSavedSearch}
                        addSavedSearch={addSavedSearch}
                        savingSearch={savingSearch}
                  />
                <Metrics metrics={items.metrics}
                        //  setSort={setSort}
                        //  setType={setType}
                        //  setSites={setSites}
                         lastQuery={lastQuery} 
                        //  setPriceMin={setPriceMin}
                        //  setPriceMax={setPriceMax}
                        //  setPWCCWeeklies={setPWCCWeeklies}
                        //  setPWCCPremiers={setPWCCPremiers}
                         lastBody={lastBody} 
                         runSearch={runSearch}
                         />
                  {(count === 0 && filteredToZero) ? <FilteredToZero /> :
                    <div className="wrapper-grid">
                        <div className="row">
                            {isFilteringSort ? data.map((item, key) => (<PlaceholderCard data={item} key={key} />)) : data.map((item, key) => (<Card data={item} key={key} />))}
                        </div>
                    </div>
}
                
            </div>
        }
    </>
    )

}

export default Container;