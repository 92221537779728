import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import About from './Header/About';
import Faq from './Header/Faq';
import Contact from './Header/Contact'
import Home from './Home';
import Terms from './Header/Terms';
import Privacy from './Header/Privacy';
import Disclaimer from './Header/Disclaimer';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/about" element={<About/>}/>
                <Route path="/faq" element={ <Faq />}/>
                <Route path="/contact" element={<Contact/>}/ >
                <Route path="/privacy" element={<Privacy/>}/>                
                <Route path="/terms" element={<Terms/>}/>                
                <Route path="/disclaimer" element={<Disclaimer/>}/>                
                 <Route path="/" element={<Home />}/>
            </Routes>
        </Router>
    )
}

