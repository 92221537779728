import { useAuth0 } from "@auth0/auth0-react";
import { Button as Button } from 'rsuite';

const AuthenticatedHeader  = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

    if(isLoading) {
        return <div>Loading...</div>
    }

    return (
         <>
               {!isAuthenticated ? <>
            <Button color="green" appearance="ghost" onClick={() => loginWithRedirect()}>Log In</Button>
            <Button color="green" appearance="primary" onClick={() => loginWithRedirect()}>Create Account</Button></>
            :<Button color="green" appearance="ghost" onClick={() =>  logout({ returnTo: window.location.origin })}>Log Out</Button>
               }
               </>
    );
};

export default AuthenticatedHeader;