import Footer from '../Footer';
import SiteHeader from './SiteHeader';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const Faq = () => {
    return (
        <>
        <SiteHeader />
            <main>
            <div className="faq">
                <h2>FAQs</h2>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            What is Hobby Exchange?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>A one-stop search engine aggregating for sale items listed on numerous platforms.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            What platforms does Hobby Exchange support?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>Presently eBay, PWCC, COMC, ALt, MySlabs, Prospect Card, Goldin, and Heritage. </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            What platforms do you plan to add?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>We are always looking for new partners.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                            What features would you like to see added?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>Drop us a line at hello@hobby.exchange </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Faq;