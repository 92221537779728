import { useQuery } from '@tanstack/react-query'
import TopTen from './TopTen';
const HomePageTopTenLists = () => {
    const getList = async () => {
        const res = await fetch('https://api.hobby.exchange/homepage', { headers: { 'Content-Type': 'application/json' } });
        if (!res.ok) throw Error(res.statusText);
        return res.json();
    }

    const { data, error, isLoading } = useQuery({
        queryKey: ['topTenLists'],
        queryFn: getList
    })

    if (isLoading) return <div className="row mt-3 mb-5">Loading...</div>
    if (error) return <div className="row mt-3 mb-5"> Error loading lists</div>

    return (
        <>
        <div className="row mt-5">
            <div className="col-md"><TopTen data={data.e} isEbay={true} heading='Top 10 Most Watched Cards On Ebay' numbered /></div>
        </div>
         {/* <div className="row mt-3 mb-5">
            <div className="col-md"><TopTen data={data.p} isEbay={true} heading='10 Highlights of PWCC Auctions' numbered={false} />
            </div>
            <div className="col-md"><TopTen data={data.p} isEbay={false} heading='10 Highlights of PWCC Auctions' numbered={false} /></div>
            <div className="col-md"><TopTen data={data.ha} isEbay={false} heading='10 Highlights of Heritage Auctions' numbered={false} /></div> 
            <div className="col-md"><TopTen data={topGoldin} heading='10 Highlights of Goldin Auctions' numbered={false} /></div>
        </div>  */}
        </>
    )

}


export default HomePageTopTenLists;