import React from "react";
import ContentLoader from "react-content-loader";
const PlaceholderCard = (item, key) => {
    return (
        <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="box_grid">
                <div className="wrapperPlaceholder">
                    <ContentLoader viewBox="0 0 500 280" height={280} width={1100} speed={3}>
                        <rect x="3" y="3" rx="10" ry="10" width="4300" height="180" />
                        <rect x="6" y="190" rx="0" ry="0" width="2292" height="20" />
                        <rect x="4" y="215" rx="0" ry="0" width="2239" height="20" />
                        <rect x="4" y="242" rx="0" ry="0" width="2274" height="20" />
    </ContentLoader>
                </div>

            </div>
        </div>
    )
}
export default PlaceholderCard;