
import Footer from '../Footer';
import SiteHeader from './SiteHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
const Contact = () => {

    return (
        <>
             <SiteHeader />
            <main>
                <section className="hero_in contacts">
                    <div className="wrapper">
                        <div className="container">
                            <h1 className="fadeInUp"><span></span>Contact Us</h1>
                        </div>
                    </div>
                </section>
                <div className="contact_info">
                    <div className="container">
                        <ul className="clearfix">
                            <li>
                                {/* <FontAwesomeIcon icon={faMap} size="5x" />
                                <h4>Address</h4>
                                <span>PO Box 97845 Baker st. 567, Los Angeles<br />California - US</span> */}
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faEnvelopeOpen} size="5x" />
                                <h4>Email Address</h4>
                                <span>hello@hobby.exchange</span>
                            </li>
                            <li>
                                {/* <FontAwesomeIcon icon={faBell} size="5x" />
                                <h4>Contacts Info</h4>
                                <span>Call us</span> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Contact;