import PhotoAlbum from "react-photo-album";

const TopTen = (list) => {
    // console.log(`top ten list is ${list} title is ${list.heading}`)
    function truncate(str) {
        return str.length > 50 ? str.substring(0,47) + "...": str;
    }

    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

    const photos = list.data.map((photo) => ({
        src: photo.img,
        width: 260,
        height: 400,
        link:photo.link,
        srcSet:breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.img,
                link:photo.link,
                width: breakpoint,
                height,
            }
        })
    }));

    if(list.isEbay){
        return(
            <> 
             <h4>{list.heading}</h4>
            <PhotoAlbum photos={photos} layout="masonry" onClick={({ photo }) => { 
                window.open(photo.link, '_blank')}} />
                </>
        )
    }

//     return(
//         <>
//             <h4>{list.heading}</h4>
//             <div className="text-left mx-auto">
//             <ul className="tenlist">
//                 {list.data.map((e,idx) => {
//                     return (
                    
//                         <li className="tenlist" key={idx}><a href={e.link} target="_blank" rel="noopener">{truncate(e.title)}</a></li>
//                     )
// })}
//             </ul>
//             </div>
//         </>
//     )
};

export default TopTen;