import './App.css';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { css } from "@emotion/react";
import FadeLoader from 'react-spinners/FadeLoader';

import Container from './Results/Container';
import Footer from './Footer';
import Main from './Carousel/main';
import SiteHeader from './Header/SiteHeader';
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from 'react-toastify';
import { getTransformedResults, useSearch, useHaOnlySearch, usePremiersOnlySearch, useGoldinOnlySearch, useNewslettersOnlySearch } from './searchQueries';

const Home = () => {
    const location = useLocation();
    
    const [lastSuccessfulQuery, setLastSuccessfulQuery] = useState(null);
    const [searchType, setSearchType] = useState('ALL');
    const [sort, setSort] = useState('PriceAsc');
    const [sitestoSearch, setSitesToSearch] = useState(["ebay", "pwcc", "myslabs", "comc", "alt", "pc", "ha", "gn", "fan", "arena"])
    const [filteringSort, setFilteringSort] = useState(false);
    const [filtredToZero, setFilteredToZero] = useState(false);
    
    const [priceMin, setPriceMin] = useState(0);
    const [priceMax, setPriceMax] = useState(0);
    const [lastBody, setLastBody] = useState();
    const [pwccWeeklyAuctions, setPwccWeeklyAuctions] = useState(true);
    const [pwccPremierAuctions, setPwccPremierAuctions] = useState(true);
    const [addSavedSearch, setAddSavedSearch] = useState(false);
    const [ajaxInActionSave, setAjaxInActionSave] = useState(false);
    const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
    const [runPremiersOnlySearch, setRunPremiersOnlySearch] = useState(false);
    const [runHeritageOnlySearch, setRunHeritageOnlySearch] = useState(false);
    const [runGoldinOnlySearch, setRunGoldinOnlySearch] = useState(false);
    const [runNewsletterSearch, setRunNewsletterSearch] = useState(false);


    const notifySavedSearch = (text, error = false) => {
        if (!error) {
            toast.success(`Saved Search - ${text} was saved. View your saved searches in the my account tab.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warn(`Error saving saved search - ${text}. Please try again`, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }
    }

    let { data, error, isLoading: isLoadingServer, isFetching, refetch } = useSearch(lastSuccessfulQuery, lastBody)
    const gnonly = useGoldinOnlySearch(runGoldinOnlySearch);
    const haonly = useHaOnlySearch(runHeritageOnlySearch)
    const newsonly = useNewslettersOnlySearch(runNewsletterSearch);
    const premonly = usePremiersOnlySearch(runPremiersOnlySearch);



    const genBody = () => {
        return {
            datasources: ["ebay", "pwcc", "myslabs", "comc", "alt", "pc", "ha", "gn", "fan", "arena"],
            type: 'ALL',
            sort: 'PriceAsc',
            priceLow: 0,
            priceHigh: 0
        };
    }
    const runSearch = (q) => {
        let body = genBody();
        setLastSuccessfulQuery(q);
        setLastBody(body);
    }
    const runSearch2 = (q) => {
        //since this is from header do not filter grab all the things
        let body = genBody();
        setLastSuccessfulQuery(q);
        setLastBody(body);
    }
    const fakeSetQueryForMainJS = (q,
        isAdvanced,
        sites,
        sort,
        type,
        pw = pwccWeeklyAuctions,
        pwa = pwccPremierAuctions,
        pmin = priceMin,
        pmax = priceMax) => {
        if (isAdvanced) {
            let body = {
                datasources: sites,
                type: type,
                sort: sort,
                priceLow: pmin,
                priceHigh: pmax,
                pww: pw,
                pwa: pwa
            }
            setLastSuccessfulQuery(q);
            setLastBody(body);
            setSitesToSearch(sites);
            setSort(sort);
            setSearchType(type);
        } else {
            runSearch(q)
        }
    }
    
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const p = searchParams.get('keywords');
        const runAuto = searchParams.get('a');
        const runFiltered = searchParams.get('f');
        const runPremiersOnly = searchParams.get('premiers');
        const runHeritageOnly = searchParams.get('heritage');
        const runGoldinOnly = searchParams.get('goldin');
        const runNewsletterOnlySearch = searchParams.get('newsletter');
        if (runPremiersOnly || runHeritageOnly || runGoldinOnly || runNewsletterOnlySearch) {
            if (runNewsletterOnlySearch !== null) {
                const newsBody = {
                    datasources: ["gn", "ebay", "pwcc", "ha"],
                    type: 'ALL',
                    sort: 'PriceAsc',
                    priceLow: 0,
                    priceHigh: 0
                };
                setLastSuccessfulQuery('newsletter');
                setLastBody(newsBody);
                setRunNewsletterSearch(true)
            }
            else if (runPremiersOnly !== null) {
                const premiersBody = {
                    datasources: ["pwcc"],
                    type: 'ALL',
                    sort: 'PriceAsc',
                    priceLow: 0,
                    priceHigh: 0
                };
                setLastSuccessfulQuery('premiersonly');
                setLastBody(premiersBody);
                setRunPremiersOnlySearch(true);
            } else if (runHeritageOnly) {
                const haBody = {
                    datasources: ["ha"],
                    type: 'ALL',
                    sort: 'PriceAsc',
                    priceLow: 0,
                    priceHigh: 0
                };
                setLastSuccessfulQuery('heritageonly');
                setLastBody(haBody);
                setRunHeritageOnlySearch(true);
            } else {
                const gnBody = {
                    datasources: ["gn"],
                    type: 'ALL',
                    sort: 'PriceAsc',
                    priceLow: 0,
                    priceHigh: 0
                };
                setLastSuccessfulQuery('goldinonly');
                setLastBody(gnBody);
                setRunGoldinOnlySearch(true);
            }
        } else {
            if (runAuto) {
                if (runFiltered) {
                    const ebay = searchParams.get('e');
                    const pwcc = searchParams.get('pw');
                    const myslabs = searchParams.get('m');
                    const comc = searchParams.get('c');
                    const alt = searchParams.get('alt');
                    const pc = searchParams.get('pc');
                    const ha = searchParams.get('ha');
                    const gn = searchParams.get('gn');
                    const fan = searchParams.get('fan');
                    const arena = searchParams.get('a');
                    const type = searchParams.get('type');
                    const sort = searchParams.get('sort');
                    const pmin = searchParams.get('pl');
                    const pmax = searchParams.get('ph');
                    const pww = searchParams.get('pww');
                    const pwa = searchParams.get('pwa');

                    const filteredSitesToSearch = [];
                    if (ebay) {
                        filteredSitesToSearch.push('ebay');
                    }
                    if (pwcc) {
                        filteredSitesToSearch.push('pwcc');
                    }
                    if (myslabs) {
                        filteredSitesToSearch.push('myslabs');
                    }
                    if (comc) {
                        filteredSitesToSearch.push('comc');
                    }
                    if (alt) {
                        filteredSitesToSearch.push('alt');
                    }
                    if (pc) {
                        filteredSitesToSearch.push('pc');
                    }
                    if (ha) {
                        filteredSitesToSearch.push('ha');
                    }
                    if (gn) {
                        filteredSitesToSearch.push('gn');
                    }
                    if (fan) {
                        filteredSitesToSearch.push('fan');
                    }
                    if (arena) {
                        filteredSitesToSearch.push('arena');
                    }

                    setSitesToSearch(filteredSitesToSearch);
                    let sortSelection = 'PriceAsc';
                    let typeSelection = 'ALL';
                    if (sort) {
                        const validSorts = ['PriceAsc', 'PriceDesc', 'EndingSoonest', 'NewlyCreated'];
                        if (validSorts.indexOf(sort) > 0) {
                            setSort(sort);
                            sortSelection = sort
                        }
                    }

                    if (type) {
                        if (type.toUpperCase() === 'ALL') {
                            setSearchType('ALL');
                        } else if (type.toUpperCase() === 'AUCTION') {
                            setSearchType('AUCTION');
                            typeSelection = 'AUCTION';
                        } else if (type.toUpperCase() === 'FIXED') {
                            setSearchType('FIXEDPRICE')
                            typeSelection = 'FIXEDPRICE'
                        } else {
                            //do nothing
                        }
                        if (p !== null && p.length > 0) {
                            fakeSetQueryForMainJS(decodeURIComponent(p), true, filteredSitesToSearch, sortSelection, typeSelection, pww, pwa, pmin, pmax);
                        }
                    }

                } else {
                    if (p !== null && p.length > 0) {
                        runSearch(decodeURIComponent(p));
                    }
                }
            }
        }
    }, [location]);


    useEffect(() => {
        const addSavedSearch = async () => {
            try {
                setAjaxInActionSave(true);
                const token = await getAccessTokenSilently({
                    audience: 'https://hobby-api',
                    scope: 'read:savedsearches'
                });
                const searches = []
                const d = {
                    keywords: decodeURIComponent(lastSuccessfulQuery),
                    body: lastBody
                }
                searches.push(d);
                const data = {
                    ss: { searches: searches }
                }

                const url = 'https://api.hobby.exchange/savedsearch/add';
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                const r = await response.json();
                if (response.ok) {
                    notifySavedSearch(decodeURIComponent(lastSuccessfulQuery))
                    setAddSavedSearch(false);
                    setAjaxInActionSave(false);
                } else {
                    setAddSavedSearch(false);
                    setAjaxInActionSave(false);
                }
            } catch (e) {
                notifySavedSearch(decodeURIComponent(lastSuccessfulQuery), true)
                setAjaxInActionSave(false);
                setAddSavedSearch(false);
                console.error(e);
            }

        }
        //check again logged in? 
        if (!isLoading && isAuthenticated && !ajaxInActionSave) {
            addSavedSearch();
        }
    }, [addSavedSearch])

    const override = css`
  display: block;
  margin: 0 auto;
  padding: 20% 0%;
  
`;

    const color = '#edc773'
    const style = {
        margin: 125
    }

    let hasData = () => {
        return data || gnonly.data || haonly.data || newsonly.data || premonly.data
    }
    let getData = () => {
        if (premonly.data) {
            return premonly.data;
        } else if (newsonly.data) {
            return newsonly.data
        } else if (haonly.data) {
            return haonly.data;
        } else if (gnonly.data) {
            return gnonly.data;
        } else {
            return data;
        }
    }
    let rtd = hasData() ? getTransformedResults(getData(), lastSuccessfulQuery) : { query: '' };
    return (<>

        <SiteHeader showSearch={hasData() ? true : false}
            setQuery={runSearch2}
            lastQuery={lastSuccessfulQuery}
        />
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
        />
        {hasData() ?
            <Container items={rtd}
                lastQuery={rtd.query}
                setSort={setSort}
                setType={setSearchType}
                setSites={setSitesToSearch}
                isFilteringSort={filteringSort}
                runSearch={fakeSetQueryForMainJS}
                filteredToZero={filtredToZero}
                setPriceMin={setPriceMin}
                setPriceMax={setPriceMax}
                setPWCCWeeklies={setPwccWeeklyAuctions}
                setPWCCPremiers={setPwccPremierAuctions}
                lastBody={lastBody}
                setAddSavedSearch={setAddSavedSearch}
                addSavedSearch={addSavedSearch}
                savingSearch={ajaxInActionSave}
            /> :
            <><div style={style}></div><FadeLoader color={color} loading={isFetching} css={override} size={55} /></>}

        {!hasData() ? <Main setQuery={fakeSetQueryForMainJS} /> : ''}

        <Footer />
    </>
    );
}

export default Home;
