import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF,faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import logo from '../Header/HobbyExchange_Black_Gold_1.png';

const footer = () => {
    return (
        <footer>
            <div className="container margin_60_35 footer mt-auto">
                <div className="row">
                    <div className="col-lg-5 col-md-12 p-r-5">
                        <img src={logo} alt="hobby exchange logo" width="160" height="60"/>
                        <p className="text-gold">Hobby Exchange is THE search engine for trading cards. We aggregate results from the leading marketplaces all onto one platform. Are you looking for the best prices? We can help!</p>
                        <br/>
                        <p className="text-gold">When you click on links to various merchants on this site and make a purchase, this can result in this site earning a commission. Affiliate programs and affiliations include, but are not limited to, the eBay Partner Network.”</p>
                        <div className="follow_us">
                            <ul>
                                <li>Follow us</li>
                                <li><a href="https://www.facebook.com/TheHobbyExchange" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebookF}/></a></li>
                                <li><a href="https://twitter.com/hobby__exchange" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faTwitter}/></a></li>
                                <li><a href="https://www.instagram.com/hobby.exchange" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faInstagram}/></a></li>
                                <li><a href="https://www.youtube.com/channel/UCT16fgZQ4R1axs0w9wv0hAA" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faYoutube}/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ml-lg-auto">
                        <h5>Useful links</h5>
                        <ul className="links">
                            <li><span><Link to="/">Home</Link></span></li>
                            <li><span><Link to="/about">About</Link></span></li>
                            <li><span><Link to="/faq">FAQ</Link></span></li>
                            <li><span><Link to="/contact">Contact</Link></span></li>
                            <li><span><Link to="/privacy">Privacy</Link></span></li>
                            <li><span><Link to="/terms">Terms Of Use</Link></span></li>
                            <li><span><Link to="/disclaimer">Disclaimer</Link></span></li>

                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5>Contact Us</h5>
                        <ul className="contacts">
                            <li><Link to="mailto:hello@hobbyexchnge.com"><FontAwesomeIcon icon={faEnvelope}/> hello@hobby.exchange</Link></li>
                        </ul>
                        <div id="newsletter">
                            <h6>Newsletter</h6>
                            <a data-formkit-toggle="49a8502115" href="https://awesome-painter-5972.ck.page/49a8502115">Click Here To Subscribe!</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    );
};
export default footer;