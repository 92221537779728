const FilteredToZero = () => {
    return (
        <div className="wrapper-grid">
            <div className="row">
                <div className="noneResults">
                    <h3 className="noresults">The filter options you selected found zero results. Click on the filters menu and adjust your parameters or hit reset search.</h3>
                </div>
            </div>
        </div>
    )
}
export default FilteredToZero;