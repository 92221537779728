import SiteHeader from './SiteHeader';
import Footer from '../Footer';
const Disclaimer = () => {
  return (  <>
        <SiteHeader />
        <div className="col-lg-11">
            <p><span style={{"color": "#3d3d3d"}}>We may receive compensation in the form of affiliate marketing payments in the event you purchase items linked from </span><a href="https://hobby.exchange/" target="_blank" rel="noopener"><span style={{"color": "#3d3d3d"}}>https://hobby.exchange</span></a><span style={{"color": "#3d3d3d"}}>.</span></p>
            <p><span style={{"color": "#3d3d3d"}}>We strive to provide the most accurate and comprehensive search results, however rely on the data provided by third parties. For this reason we cannot guarantee all results are accurate nor can we guarantee our results are complete due to technical &ldquo;call limits&rdquo; and other constraints in showing data.&nbsp; Please be aware the results shown may be incomplete.&nbsp;&nbsp;</span></p>
            <p><span style={{"color": "#3d3d3d"}}>Investing in trading cards and memorabilia involves substantial risk of loss and is not suitable for everyone. Discuss your suitability with a registered financial advisor before making any financial decisions.&nbsp;&nbsp;</span></p>
            <p><span style={{"color": "#3d3d3d"}}>&copy; 2022 Hobby Exchange Inc. All rights reserved.</span></p>
        </div>
        <Footer />
    </>)
}
export default Disclaimer;