import { Link } from 'react-router-dom';

import {LinkContainer} from 'react-router-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { useSearchParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Button as RButton } from 'rsuite';
import { QueryCache } from '@tanstack/react-query'


import Button from 'react-bootstrap/Button';
import logo from './HobbyExchange_Black_Gold_1.png';
import {useState, useEffect} from 'react';
import AuthenticatedHeader from'./AuthenticatedHeader';
import SavedSearchList from './SavedSearchList';

const SiteHeader = ({showSearch, setQuery, lastQuery}) => {
  const { loginWithRedirect, logout ,user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
   const [localQuery, setLocalQuery] = useState(lastQuery);
   let [searchParams, setSearchParams] = useSearchParams();
   const [open, setOpen] = useState(false);
   const [placement, setPlacement] = useState(); 
   useEffect(() => {
     setLocalQuery(lastQuery)
   }, [lastQuery])



   const submitSearch = (e) => {
     e.preventDefault();

     
    //  const params = new URLSearchParams()
    //  params.append("keywords",localQuery);
    let q = localQuery
     setSearchParams({keywords:q,a:false })
    //  history.push({search: params.toString()})
    setQuery(localQuery);
   }
   const handleOpen = (e,key) => {
     e.preventDefault();
    setOpen(true);
    setPlacement(key);
};

const isLoggedIn = () => !isLoading && isAuthenticated
const getUser = () => {
  if(!isLoading && isAuthenticated){
    return user;
  }else {
    return {
      name:''
    }
  }
}
  return (
<>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <Container>
        <LinkContainer to="/" onClick={() => QueryCache.clear()}>
        <Navbar.Brand><img src={logo} alt="hobby exchange logo" width="160" height="60"/></Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        {showSearch ? 
                <Form className="d-flex" onSubmit={submitSearch}>
                {/* <Form.Control type="text" placeholder="Player, grade..." size="md" className=" mr-sm-2" value={(searchQuery && searchQuery.length) > 0 ? searchQuery : lastQuery} onInput={e => setQuery(e.target.value)} /> */}
                <Form.Control type="text" placeholder="Player, grade..." size="md" className=" mr-sm-2" value={localQuery} onInput={e => setLocalQuery(e.target.value)} />
                <Button variant="warning" type="submit">Search</Button>
              </Form>
              :""}
          <Nav >
            <Nav.Link as={Link} to="/" onClick={() => QueryCache.clear()}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            <Nav.Link as={Link} to="" onClick={e => handleOpen(e,'right')}>My Account | <FontAwesomeIcon icon={faUser} /></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Drawer placement={placement} size={'xs'} open={open} onClose={() => setOpen(false)} className="rs-theme-dark">
                <Drawer.Header style={{ backgroundColor: '#121921' }}>
                    <Drawer.Title style={{ color: '#edc773' }}>My Account</Drawer.Title>
                    <Drawer.Actions>
                       <AuthenticatedHeader />
                    </Drawer.Actions>
                    </Drawer.Header>
                <Drawer.Body style={{ backgroundColor: '#121921' }}>
                <h3 className="text-gold"> Your Saved Searches: </h3>
                <SavedSearchList />
                </Drawer.Body>
            </Drawer>
    </>
  )
}

export default SiteHeader;