import Warning from "./warning";
import { Button, IconButton } from 'rsuite';

import PlusRoundIcon from '@rsuite/icons/PlusRound';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useState } from 'react';


const Header = ({ count, highResultCount, lastQuery, setAddSavedSearch, addSavedSearch, savingSearch = { savingSearch } }) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const PlusIcon = ({ color, size }) => (
        <PlusRoundIcon style={{ color, fontSize: size }} />
    );

    const stopPropogationDoSaveSearch = (e, saveSearch) => {
        e.preventDefault();
        if (!addSavedSearch) {
            setButtonDisabled(true);
            setAddSavedSearch(saveSearch)
        }
    }

    const handleAuth = e => {
        e.preventDefault()
        loginWithRedirect({ redirectUri: window.location.href })
    }
    //   const getSpinner = loading => {
    //       console.log(`get spinner called with ${loading}`)
    //           if(loading){
    //                )
    //           } else {

    //           }
    //   }
    return (
        <div className="filters_listing stickySubHeader">
            <div className="container">
                <ul className="clearfix">
                    <li>
                    </li>
                    {highResultCount > 500 ? <Warning count={highResultCount} /> : ''}
                    <li>
                        <div className="layout_view"><div className="text-gold-inline">{count}</div> card{count > 1 ? 's' : ''} found</div>
                    </li>
                    <li>
                        <div className="layout_view"><div className="text-gold-inline">You searched for:</div> {lastQuery}</div>
                    </li>

                    {isAuthenticated ? <li>
                        <div className="text-gold-inline"><FontAwesomeIcon icon={faPlusCircle} onClick={e => stopPropogationDoSaveSearch(e, true)} />
                            {savingSearch ? <PulseLoader color="#edc773" size={16} loading /> : <Link to="" onClick={e => stopPropogationDoSaveSearch(e, true)} className="savedsearchlink">Click Here To Save This Search</Link>}
                        </div>
                    </li>
                        : <li>
                            <PlusIcon color="#edc773" size="20" />
                            <div className="text-gold-inline">  <Link to="" onClick={e => handleAuth(e)}>Sign In To Save This Search.</Link></div>

                        </li>}
                </ul>
            </div>
        </div>
    )
}

export default Header;